import { Route, Routes } from 'react-router-dom'
import Main from './pages/main'

const App = () => {
  return (
    <Routes>
      <Route path="/pdn/" element={<Main />}></Route>
    </Routes>
  )
}

export default App
