export interface InputValueResponse {
  errorCode: number
}

const inputValue = async (
  id: string,
  tel: string
): Promise<InputValueResponse | string> => {
  return await fetch('/api/msisdn-collection/save', {
    method: 'POST',
    body: JSON.stringify({ msisdn: tel, muid: id }),
    headers: {
      'Content-type': 'application/json'
    }
  }).then((result) => result.json())
}
const inputValueUTM = async (
  id: string,
  tel: string
): Promise<InputValueResponse | string> => {
  return await fetch('/api/msisdn-collection/save', {
    method: 'POST',
    body: JSON.stringify({ msisdn: tel, utm: id }),
    headers: {
      'Content-type': 'application/json'
    }
  }).then((result) => result.json())
}
export { inputValue, inputValueUTM }
