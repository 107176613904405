import { FC, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import CloseIcon from '../commons/CloseIcon'
import { useMatchMedia } from '../hooks/useMatchMedia'

interface PortalProps {
  children: React.ReactNode
}
interface ModalSuccessProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}
const Portal: FC<PortalProps> = ({ children }) => {
  const [container] = useState(document.createElement('div'))

  useEffect(() => {
    container.className = 'portal'
      ? 'portal-custom-modal-dark'
      : 'portal-custom-modal-light'

    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line import/no-named-as-default-member
  return ReactDOM.createPortal(children, container)
}

const ModalSuccess: FC<ModalSuccessProps> = ({ setOpen }) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const matchMobile = useMatchMedia('(max-width: 680px)')
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handdeleClick = () => {
    setOpen(false)
  }
  return (
    <Portal>
      <div
        className="portal-city-choose"
        style={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0%',
          left: '0%',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(108, 122, 137,0.8)'
        }}
      >
        <div
          ref={modalRef}
          style={{
            top: '0%',
            left: '0%',
            width: matchMobile ? '50%' : '20%',
            height: matchMobile ? '10%' : '10%',
            backgroundColor: 'white',
            boxShadow: '6px 6px 10px rgba(0, 0, 0, 0.5)',
            borderRadius: '10px',
            paddingRight: '8px',
            paddingTop: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              cursor: 'pointer'
            }}
          >
            <CloseIcon handdeleClick={handdeleClick} />
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              height: '80%',
              color: '#5d616f',
              fontWeight: '600',
              fontSize: '24px',
              alignItems: 'end'
            }}
          >
            <p>Успешно!</p>
          </div>
        </div>
      </div>
    </Portal>
  )
}
export default ModalSuccess
