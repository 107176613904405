import LogoSRC from '../assets/logo.png'
import { useMatchMedia } from '../hooks/useMatchMedia'

const Logo = () => {
  const matchMobile = useMatchMedia('(max-width: 600px)')
  return (
    <img
      src={LogoSRC}
      alt="Комплекс социального развития Москвы"
      style={{
        width: matchMobile ? '70%' : '30%',
        height: matchMobile ? '70%' : '30%'
      }}
    ></img>
  )
}
export default Logo
