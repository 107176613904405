import { FC } from 'react'

interface CloseIconProps {
  handdeleClick: () => void
}

const CloseIcon: FC<CloseIconProps> = ({ handdeleClick }) => {
  return (
    <svg
      onClick={handdeleClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Path"
            d="M18 6L6 18"
            stroke="#5D6685"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_2"
            d="M18 18L6 6"
            stroke="#5D6685"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  )
}
export default CloseIcon
