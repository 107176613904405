import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { InputValueResponse, inputValue, inputValueUTM } from '../api/api'
import './style.css'
import ModalSuccess from '../components/Portal'
import Logo from '../commons/Logo'

const Main: FC = () => {
  const [valueInput, setValueInput] = useState('')
  const [error, setError] = useState('')
  const [apiError, setApiError] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [open, setOpen] = useState(false)
  const location = useLocation()

  const PHONE_REGEX = /^(8|\+7) \d{3}-\d{3}-\d{4}$/

  const inputMask = (value: string): string => {
    value = value.replace(/\D/g, '')
    if (value.length > 0 && value[0] !== '7' && value[0] !== '8') {
      value = '+7' + value
    }
    if (value.length > 0 && value[0] === '7') {
      value = '+7' + value.substr(1)
    }
    if (value.length > 0 && value[0] === '8') {
      value = '+7' + value.substr(1)
    }
    if (value.length > 2) {
      value = value.replace(/^(\+\d{1})(\d{3})/, '$1 $2')
    }
    if (value.length > 6) {
      value = value.replace(/^(\+\d{1} \d{3})(\d{3})/, '$1-$2')
    }
    if (value.length > 9 && value.length <= 15) {
      value = value.replace(/^(\+\d{1} \d{3}-\d{3})(\d{2})/, '$1-$2')
    }
    return value
  }
  const submitValue = () => {
    if (valueInput === '')
      return setError('Поле с телефоном не может быть пустым')
    if (!PHONE_REGEX.test(valueInput))
      return setError(
        'Номер телефона указан неверно.\n' +
          'Введите номер телефона в формате +7 999-999-9999 или 8 999-999-9999'
      )
    const url = location.search?.split('muid=')[1]
    const urlUTM = location.search?.split('utm=')[1]
    if (url || urlUTM) {
      const regex = /([^&]+)/

      if (url) {
        const match = url.match(regex)
        const muid = match ? match[1] : null
        if (muid) {
          inputValue(muid, valueInput.replace(/[^0-9]/g, ''))
            .then((res: InputValueResponse | string) => {
              if (typeof res !== 'string' && res.errorCode === 431)
                return setApiError('Телефон уже зарегистрирован')
              if (typeof res !== 'string' && res.errorCode !== 0)
                return setApiError('Ошибка апи или не верный muid')
              setOpen(true)
              return setApiError('')
            })
            .catch((e) => console.log(e))
        }
        setDisabled(true)
      }
      if (urlUTM) {
        const matchUTM = urlUTM.match(regex)
        const utm = matchUTM ? matchUTM[1] : null
        if (utm) {
          inputValueUTM(utm, valueInput.replace(/[^0-9]/g, ''))
            .then((res: InputValueResponse | string) => {
              if (typeof res !== 'string' && res.errorCode === 431)
                return setApiError('Телефон уже зарегистрирован')
              if (typeof res !== 'string' && res.errorCode !== 0)
                return setApiError('Ошибка апи или не верный utm')
              setOpen(true)
              return setApiError('')
            })
            .catch((e) => console.log(e))
          setDisabled(true)
        }
      }
    } else {
      return setApiError('Ошибка апи или не верный muid или utm')
    }
  }

  useEffect(() => {
    setDisabled(false)
  }, [valueInput])
  return (
    <>
      <div className="body">
        <div className="logo-img">
          <Logo />
        </div>
        <div className="text">
          <p className="text-title">
            Спасибо за интерес к городской программе поддержки здоровья для
            москвичей с хроническими заболеваниями.
          </p>
          <p className="text-after">
            Укажите свой номер телефона, чтобы мы могли связаться с Вами в
            ближайшее время.
          </p>
        </div>
        <div className="signin-form">
          <input
            className="signin-form__row"
            placeholder="Номер телефона"
            type="tel"
            value={valueInput}
            onChange={(tel) => {
              if (!PHONE_REGEX.test(valueInput))
                setError(
                  'Телефон должен состоять из цифр и проходить валидацию +7 999-999-9999 или 8 999-999-9999'
                )
              setValueInput(() => inputMask(tel.target.value))
            }}
          ></input>
          <button
            className={`signin-form__row_submit${disabled ? '-disabled' : ''}`}
            type="submit"
            onClick={submitValue}
            disabled={disabled}
          >
            Отправить
          </button>
        </div>
        {!PHONE_REGEX.test(valueInput) && valueInput !== '' && (
          <div className="signin-form__footer">
            <div className="error-message"> {error} </div>
          </div>
        )}
        {error && valueInput === '' && (
          <div className="signin-form__footer">
            <div className="error-message"> {error} </div>
          </div>
        )}
        {apiError && !location.search.match('muid=') && (
          <div className="signin-form__footer">
            <div className="error-message"> {apiError} </div>
          </div>
        )}
        {open && <ModalSuccess open={open} setOpen={setOpen} />}
      </div>
    </>
  )
}

export default Main
